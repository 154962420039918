import { FC } from 'react';
import { Container, Row } from 'react-bootstrap';

interface IContent {
  children: React.ReactNode;
}

const Content: FC<IContent> = ({ children }) => {
  return (
    <div className='content'>
      <Container>
        <Row>{children}</Row>
      </Container>
    </div>
  );
};

export default Content;
