import Heading from "@app/atoms/typography/heading";
import Text from "@app/atoms/typography/text";
import { FC, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaResearchgate, FaRocket } from "react-icons/fa";
import {
  MdDesignServices,
  MdOutlineDeveloperMode,
  MdOutlineSupportAgent,
} from "react-icons/md";
import Slider from "react-slick";

const OurProcess: FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sliderRef, setSliderRef] = useState(null);

  const handleSlideClick = (index: number) => {
    sliderRef.slickGoTo(index);
    setActiveIndex(index);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current: number, next: number) => setActiveIndex(next),
  };

  return (
    <section className="our-process-section-wrapper" id="process">
      <Container>
        <Heading
          className="ourProcessHeading"
          heavy
          hasAnimation
          animation="fadeInUp"
          delay={1000}
        >
          <span>
            <p>Our</p>
            <p>Process</p>
          </span>
        </Heading>
        <Text
          className="ourProcessSlugan"
          bold
          hasAnimation={true}
          animation="fadeInUp"
          delay={1500}
        >
          Some Commom Stages Used
        </Text>
        <div className="ourProcessSlider">
          <div className="slider-container">
            <div className="slider-track">
              <div
                className="slider-progress"
                style={{ width: `${(activeIndex + 1) * 20}%` }}
              ></div>
              <div className="slider-icons">
                <div
                  className={`slider-icon ${activeIndex === 0 ? "active" : ""}`}
                  onClick={() => handleSlideClick(0)}
                >
                  <i className="fas fa-home">
                    <FaResearchgate />
                    <p>Research</p>
                  </i>
                </div>
                <div
                  className={`slider-icon ${activeIndex === 1 ? "active" : ""}`}
                  onClick={() => handleSlideClick(1)}
                >
                  <i className="fas fa-heart">
                    <MdDesignServices />
                    <p>Design</p>
                  </i>
                </div>
                <div
                  className={`slider-icon ${activeIndex === 2 ? "active" : ""}`}
                  onClick={() => handleSlideClick(2)}
                >
                  <i className="fas fa-bell">
                    <MdOutlineDeveloperMode />
                    <p>Development</p>
                  </i>
                </div>
                <div
                  className={`slider-icon ${activeIndex === 3 ? "active" : ""}`}
                  onClick={() => handleSlideClick(3)}
                >
                  <i className="fas fa-cog">
                    <FaRocket />
                    <p>Launch</p>
                  </i>
                </div>
                <div
                  className={`slider-icon ${activeIndex === 4 ? "active" : ""}`}
                  onClick={() => handleSlideClick(4)}
                >
                  <i className="fas fa-cog">
                    <MdOutlineSupportAgent />
                    <p>Support</p>
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ourProcessSliderContent mt-5">
          <div className="slider-content-container">
            <Slider {...settings} ref={setSliderRef}>
              <div
                className={`container slider-content ${
                  activeIndex === 0 ? "active" : ""
                }`}
              >
                <Row>
                  <Col md={6}>
                    <p>
                      At Wattone Technologies, we understand the crucial role of
                      research in software development. Our research phase is a
                      key component of our development process, allowing us to
                      gather valuable insights and make informed decisions.
                      During this phase, our team of experienced researchers and
                      analysts delve into your project requirements, industry
                      trends, and user expectations to lay a strong foundation
                      for the development process. We believe that a
                      comprehensive research phase sets the stage for successful
                      software solutions that meet your business objectives.
                    </p>
                    <br />
                    <p>
                      During the research phase, we conduct a thorough analysis
                      of your project requirements to ensure a deep
                      understanding of your unique needs and challenges. Our
                      team collaborates closely with your stakeholders to gather
                      relevant information, clarify objectives, and identify key
                      success factors. By leveraging industry best practices and
                      conducting comprehensive market research, we gain valuable
                      insights into the competitive landscape, emerging
                      technologies, and user preferences. This knowledge
                      empowers us to design solutions that are not only
                      cutting-edge but also aligned with your business goals.
                    </p>
                  </Col>
                  <Col md={6}>
                    <p>
                      User research is a critical aspect of our software
                      development process. We employ various methods, such as
                      user interviews, surveys, and usability testing, to gain a
                      deep understanding of your target audience. By engaging
                      directly with end-users, we gather valuable feedback and
                      insights that drive our design decisions. This
                      user-centered approach enables us to create intuitive and
                      user-friendly software solutions that address your users'
                      pain points, enhance their experience, and drive
                      engagement.
                    </p>
                    <br />
                    <p>
                      Our research phase culminates in the creation of a
                      comprehensive research report, which serves as a roadmap
                      for the development process. This report outlines the key
                      findings, project scope, recommended technologies, and a
                      detailed plan of action. By providing a clear roadmap, we
                      ensure transparency and alignment with your vision and
                      goals. Our research phase sets the stage for a successful
                      software development journey, enabling us to deliver
                      high-quality solutions that exceed your expectations.
                    </p>
                  </Col>
                </Row>
              </div>
              <div
                className={`container slider-content ${
                  activeIndex === 1 ? "active" : ""
                }`}
              >
                <Row>
                  <Col md={6}>
                    <p>
                      We believe that a well-executed design phase is crucial
                      for delivering exceptional software solutions. Our design
                      phase focuses on transforming the insights gathered during
                      the research phase into tangible and visually appealing
                      designs. Our team of skilled designers collaborate closely
                      with you to understand your brand, target audience, and
                      project goals. By combining creativity with a user-centric
                      approach, we craft intuitive and aesthetically pleasing
                      designs that enhance the overall user experience.
                    </p>
                    <br />
                    <p>
                      During the design phase, we utilize industry-leading
                      design tools and methodologies to create wireframes,
                      prototypes, and mockups. These visual representations
                      allow us to showcase the layout, flow, and functionality
                      of your software solution before development begins.
                      Through iterative design cycles and user testing, we
                      ensure that the final design aligns perfectly with your
                      requirements and provides a seamless user experience. Our
                      goal is to create designs that not only captivate users
                      but also drive engagement and deliver value to your
                      business.
                    </p>
                  </Col>
                  <Col md={6}>
                    <p>
                      Our design team understands the importance of consistency
                      and branding. We work closely with your team to ensure
                      that the design elements, such as color schemes,
                      typography, and imagery, are aligned with your brand
                      identity. This attention to detail helps to establish a
                      strong brand presence and fosters brand recognition among
                      your users. Whether it's a web application, mobile app, or
                      custom software, our design phase ensures that your
                      software solution reflects your brand's values and
                      resonates with your target audience.
                    </p>
                    <br />
                    <p>
                      The output of our design phase is a comprehensive design
                      package that serves as a guide for the development team.
                      This package includes detailed design specifications,
                      style guides, and interactive prototypes that provide
                      clear instructions and visual references. This ensures
                      seamless communication between our design and development
                      teams, leading to efficient implementation and a reduced
                      risk of misinterpretation. Our design phase plays a vital
                      role in delivering visually stunning and highly functional
                      software solutions that delight users and drive business
                      growth.
                    </p>
                  </Col>
                </Row>
              </div>
              <div
                className={`container slider-content ${
                  activeIndex === 2 ? "active" : ""
                }`}
              >
                <Row>
                  <Col md={6}>
                    <p>
                      The development phase at Wattone Technologies is where
                      your software solution truly comes to life. Our
                      experienced development team, comprised of skilled
                      engineers and programmers, diligently works to transform
                      the design concepts into a robust and functional software
                      application. During this phase, we follow industry best
                      practices and utilize cutting-edge technologies to ensure
                      the highest quality of code and deliver exceptional
                      results.
                    </p>
                    <br />
                    <p>
                      Our development process is highly collaborative, with
                      regular communication and feedback loops between our team
                      and your stakeholders. We understand the importance of
                      transparency and keeping you informed throughout the
                      development phase. Our iterative approach allows for
                      flexibility and adaptability, ensuring that your software
                      solution evolves based on your evolving needs and
                      requirements. We maintain a strong focus on delivering
                      on-time and within budget, while never compromising on
                      quality.
                    </p>
                  </Col>
                  <Col md={6}>
                    <p>
                      Our developers are skilled in various programming
                      languages, frameworks, and platforms, enabling us to
                      create customized software solutions tailored to your
                      specific needs. Whether it's web applications, mobile
                      apps, or enterprise software, we have the expertise to
                      bring your vision to reality. Throughout the development
                      phase, we conduct rigorous testing and quality assurance
                      measures to identify and resolve any bugs or issues,
                      ensuring a stable and reliable software solution.
                    </p>
                    <br />
                    <p>
                      At the end of the development phase, we deliver a fully
                      functional software solution that meets your expectations
                      and exceeds industry standards. We provide comprehensive
                      documentation and training materials to ensure a smooth
                      transition and enable your team to utilize the software
                      effectively. Additionally, we offer ongoing maintenance
                      and support services to address any future updates,
                      enhancements, or issues that may arise. Our development
                      phase is focused on delivering a high-performing software
                      solution that empowers your business and drives your
                      success.
                    </p>
                  </Col>
                </Row>
              </div>
              <div
                className={`container slider-content ${
                  activeIndex === 3 ? "active" : ""
                }`}
              >
                <Row>
                  <Col md={6}>
                    <p>
                      The launch phase marks an exciting milestone in the
                      software development journey. It is the culmination of
                      meticulous planning, dedicated efforts, and unwavering
                      commitment to delivering a top-notch software solution.
                      During this phase, our team works closely with you to
                      ensure a seamless and successful launch, creating a
                      positive impact on your business and users alike.
                    </p>
                    <br />
                    <p>
                      Our launch phase begins with thorough testing and quality
                      assurance measures to ensure that your software solution
                      is stable, secure, and performs optimally across different
                      platforms and devices. We conduct rigorous testing
                      scenarios, including load testing, performance testing,
                      and security testing, to identify and address any
                      potential issues. Our goal is to provide you with a
                      reliable and robust software solution that is ready to
                      meet the demands of your users.
                    </p>
                  </Col>
                  <Col md={6}>
                    <p>
                      We assist you in defining a strategic launch plan that
                      takes into account your target audience, marketing
                      efforts, and user onboarding. Our team collaborates
                      closely with your marketing team to create compelling
                      promotional materials, including website content, app
                      store descriptions, and social media campaigns. We guide
                      you in implementing effective user onboarding strategies
                      to ensure a smooth and engaging experience for your users,
                      driving adoption and satisfaction from the very beginning.
                    </p>
                    <br />
                    <p>
                      Once the launch plan is in motion, we closely monitor the
                      software solution's performance and user feedback. Our
                      team is prepared to provide immediate support and address
                      any issues that may arise. We believe that the launch
                      phase is not the end of our partnership but rather the
                      beginning of a long-term collaboration. We are dedicated
                      to your success and offer ongoing support, maintenance,
                      and updates to ensure that your software solution
                      continues to evolve and meet the evolving needs of your
                      business and users.
                    </p>
                    <br />
                    <p>
                      By leveraging our expertise and experience, we aim to make
                      the launch phase a smooth and impactful process, setting
                      the stage for your software solution's success. Our
                      ultimate goal is to help you achieve your business
                      objectives, drive user satisfaction, and unlock new
                      opportunities for growth.
                    </p>
                  </Col>
                </Row>
              </div>
              <div
                className={`container slider-content ${
                  activeIndex === 4 ? "active" : ""
                }`}
              >
                <Row>
                  <Col md={6}>
                    <p>
                      We understand that the support phase is a critical part of
                      the software development lifecycle. Once your software
                      solution is launched, we are committed to providing
                      comprehensive support services to ensure its ongoing
                      success. Our support phase is designed to address any
                      issues, implement updates and enhancements, and ensure
                      that your software solution continues to deliver value to
                      your business and users.
                    </p>
                    <br />
                    <p>
                      Our support team consists of highly skilled professionals
                      who are readily available to assist you with any technical
                      or functional queries. We offer various support channels,
                      including email, phone, and a dedicated support portal,
                      ensuring that you have easy access to our team whenever
                      you need assistance. Our knowledgeable support staff will
                      work diligently to understand your concerns and provide
                      prompt and effective solutions, ensuring minimal
                      disruption to your business operations.
                    </p>
                  </Col>
                  <Col md={6}>
                    <p>
                      In addition to resolving issues, our support phase
                      includes proactive monitoring and maintenance of your
                      software solution. We continuously monitor its
                      performance, security, and scalability to identify any
                      potential issues before they impact your users. Our team
                      stays updated with the latest industry trends and
                      technology advancements to provide regular updates and
                      enhancements to your software solution, ensuring it
                      remains competitive and aligned with your evolving
                      business needs.
                    </p>
                    <br />
                    <p>
                      We value our long-term partnerships and believe in
                      fostering strong relationships with our clients. Our
                      support phase extends beyond technical assistance to
                      include ongoing communication, collaboration, and
                      knowledge transfer. We provide regular updates and reports
                      on the status of your software solution, share insights
                      and recommendations for improvement, and keep you informed
                      about any industry or regulatory changes that may affect
                      your software. Our support phase is aimed at empowering
                      you with the necessary resources and expertise to maximize
                      the value of your software solution.
                    </p>
                    <br />
                    <p>
                      By choosing Wattone Technologies, you can rest assured
                      that your software solution is in capable hands during the
                      support phase. Our dedicated support team is committed to
                      providing exceptional service, ensuring the stability,
                      performance, and continued success of your software
                      solution. We are here to support you every step of the way
                      and help you navigate the evolving technology landscape
                      with confidence.
                    </p>
                  </Col>
                </Row>
              </div>
            </Slider>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default OurProcess;
