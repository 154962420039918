import { FC, useState, Children } from 'react';
import SlickSlider from 'react-slick';
import Animate from '@app/atoms/animations/animate';

interface ISlider {
  children?: React.ReactNode;
}

interface IDots {
  autoplay: boolean;
  slidesCount: number;
  currentSlide: number;
  onPlayToggle: () => void;
  gotoSlide: (index: number) => void;
}

const Dots: FC<IDots> = ({
  autoplay,
  slidesCount,
  currentSlide,
  onPlayToggle,
  gotoSlide,
}) => {
  //Dots JSX
  const dots = [];
  //Creating dots
  for (let i = 0; i < slidesCount; i++) {
    dots.push(
      <li key={i} className={`dot ${currentSlide === i ? 'active' : ''} `}>
        <span onClick={() => gotoSlide(i)}></span>
      </li>
    );
  }
  //Return JSX
  return (
    <Animate type='fadeInUp' delay={2000}>
      <div className='slider-dots-wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <ul className='slider-dots'>
                <li className='play-pause'>
                  <button onClick={onPlayToggle} aria-label='Slider Play and Pause'>
                    <i className={autoplay ? 'icon-pause' : 'icon-play'}></i>
                  </button>
                </li>
                {dots}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  );
};

const Slider: FC<ISlider> = ({ children }) => {
  //Slider reference
  let sliderRef: SlickSlider | null = null;
  //Autoplay state
  const [autoplay, setAutoplay] = useState(true);
  //Current active slide
  const [currentSlide, setCurrentSlide] = useState(0);
  //Slides count
  const slidesCount = Children.count(children);

  /**
   * Method to play the slider
   */
  const play = () => {
    if (sliderRef) {
      sliderRef.slickPlay();
    }
  };

  /**
   * Method to pause slider
   */
  const pause = () => {
    if (sliderRef) {
      sliderRef.slickPause();
    }
  };

  /**
   * Method to change slide
   */
  const gotoSlide = (index: number) => {
    if (sliderRef) {
      sliderRef.slickGoTo(index);
    }
  };

  /**
   * Method to toggle slider autoplay state
   */
  const toggleAutoplay = () => {
    autoplay ? pause() : play();
    setAutoplay(!autoplay);
  };

  //Slider Options
  const settings = {
    fade: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 7000,
    swipe: false,
    dots: false,
    arrows: false,
    pauseOnHover: false,
    afterChange: (index: number) => {
      setCurrentSlide(index);
    },
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     // setCurrentSlide(inde)
  //   }, 7000);
  //   return () => clearTimeout(timer)
  // }, [current])
  //JSX
  return (
    <>
      <SlickSlider {...settings} ref={(slider) => (sliderRef = slider)}>
        {children}
      </SlickSlider>
      <Dots
        autoplay={autoplay}
        onPlayToggle={toggleAutoplay}
        slidesCount={slidesCount}
        gotoSlide={gotoSlide}
        currentSlide={currentSlide}
      />
    </>
  );
};

export default Slider;
