import { FC } from 'react';
import { Col, Button } from 'react-bootstrap';
import { Banner, Slide, Content } from '@app/atoms/hero';
import Heading from '@app/atoms/typography/heading';
import Text from '@app/atoms/typography/text';
import sliderone from '@public/images/home-slider/sliderone.jpg';
import slidertwo from '@public/images/home-slider/slidertwo.jpg';
import sliderthree from '@public/images/home-slider/sliderthree.jpg';
import sliderfour from '@public/images/home-slider/sliderfour.webp';

/**
 * Home hero slider component
 */
const HomeHeroSlider: FC = () => {
  return (
    <section className='bannerSection'>
      <Banner variant='primary'>
        <Slide src={sliderone.src}>
          <Content>
            <Col>
              <div className='sliderData text-white'>
                <div className='contentPlacement'>
                  <Heading className='sliderHeading' heavy hasAnimation animation='fadeInUp' delay={1000}>
                    TRANSFORMING BUSINESS DIGITALLY
                  </Heading>
                  <Text className='sliderDescription' hasAnimation animation='fadeInUp' delay={1500}>
                    We leverage creativity, data and tech to deliver
                  </Text>
                </div>
              </div>
            </Col>
          </Content>
        </Slide>
        <Slide src={slidertwo.src}>
          <Content>
            <Col>
              <div className='sliderData text-white'>
                <div className='contentPlacement'>
                  <Heading className='sliderHeading' heavy hasAnimation animation='fadeInUp' delay={1000}>
                    WE BUILD PRODUCTS THAT SCALE
                  </Heading>
                  <Text className='sliderDescription' hasAnimation animation='fadeInUp' delay={1500}>
                    We are the software engineering experts that enable business at scale.
                    From early stage startups to large enterprises, we focus on actual
                    technology and quality of deliverables not just empty promises.
                  </Text>
                </div>
              </div>
            </Col>
          </Content>
        </Slide>
        <Slide src={sliderthree.src}>
          <Content>
            <Col>
              <div className='sliderData text-white'>
                <div className='contentPlacement'>
                  <Heading className='sliderHeading' heavy hasAnimation animation='fadeInUp' delay={1000}>
                    A TECHNOLOGY SOLUTION COMPANY
                  </Heading>
                  <Text className='sliderDescription' hasAnimation animation='fadeInUp' delay={1500}>
                    Encouraging Technological Advancements Within The Modern Workplace
                  </Text>
                </div>
              </div>
            </Col>
          </Content>
        </Slide>
        <Slide src={sliderfour.src}>
          <Content>
            <Col>
              <div className='sliderData text-white'>
                <div className='contentPlacement'>
                  <Heading className='sliderHeading' heavy hasAnimation animation='fadeInUp' delay={1000}>
                    DO YOU HAVE THE RIGHT PROBLEM SOLVING TECHNOLOGY?
                  </Heading>
                  <Text className='sliderDescription' hasAnimation animation='fadeInUp' delay={1500}>
                    We build software for ambitious, growing companies. Our team helps
                    take your business from 1 to 10, 100 and beyond
                  </Text>
                </div>
              </div>
            </Col>
          </Content>
        </Slide>
      </Banner>
    </section>
  );
};

export default HomeHeroSlider;
