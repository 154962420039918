import React from 'react';
import Animate from '../animations/animate';

export interface IHeading<E> {
  children: React.ReactNode;
  className?: string;
  as?: E;
  italic?: boolean;
  heavy?: boolean;
  light?: boolean;
  hasAnimation?: boolean;
  animation?: string;
  delay?: number;
}

type Props<E extends React.ElementType> = React.PropsWithChildren<IHeading<E>> &
  Omit<React.ComponentPropsWithoutRef<E>, keyof IHeading<E>>;

const Heading = <E extends React.ElementType = 'h1'>({
  children,
  className,
  as,
  heavy,
  light,
  italic,
  hasAnimation,
  animation,
  delay,
}: Props<E>) => {
  const Component = as || 'h1';
  const weight = heavy ? 'heading-heavy' : light ? 'heading-light' : '';
  const style = italic ? 'font-italic' : '';
  if (hasAnimation) {
    return (
      <Animate type={animation} delay={delay}>
        <Component className={`${weight} ${style} ${className}`}>
          {children}
        </Component>
      </Animate>
    );
  }
  return (
    <Component className={`${weight} ${style} ${className}`}>
      {children}
    </Component>
  );
};

export default Heading;
