import Heading from "@app/atoms/typography/heading";
import Text from "@app/atoms/typography/text";
import { FC } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";

const OurValues: FC = () => {
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1500,
    infinite: true,
    // speed: 2000,
    // slidesToShow: 1,
    // slidesToScroll: 1,
  };

  return (
    <section className="our-values-section-wrapper">
      <Container>
        <Heading
          className="ourValuesHeading"
          heavy
          hasAnimation
          animation="fadeInUp"
          delay={1000}
        >
          <span>
            <p>Our</p>
            <p>Values</p>
          </span>
        </Heading>
        <Text
          className="ourValuesSlugan"
          bold
          hasAnimation={true}
          animation="fadeInUp"
          delay={1500}
        >
          Some Of our core Values
        </Text>
        <div className="ourValueSlider">
          <div className="scrolling-words-box">
            <Slider {...settings}>
              <div>
                <Heading className="ourValuesSlide">INNOVATION</Heading>
              </div>
              <div>
                <Heading className="ourValuesSlide">REPUTATION</Heading>
              </div>
              <div>
                <Heading className="ourValuesSlide">EXCELLENCE</Heading>
              </div>
            </Slider>
            <span>
              <Text className="ourValuesDescription">
                These values provide the foundation for us to exceed customers'
                expectations
              </Text>
            </span>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default OurValues;
