import Heading from "@app/atoms/typography/heading";
import Text from "@app/atoms/typography/text";
import { FC } from "react";
import { Container } from "react-bootstrap";
import { FaEnvelope, FaLocationArrow, FaMobile } from "react-icons/fa";
// import Field from "@app/atoms/form/field";
// import Button from "@app/atoms/form/button";
// import { useForm } from "react-hook-form";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";

const ContactUs: FC = () => {
  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  // const validationSchema = yup.object({
  //   name: yup.string().required("Name field is required"),
  //   email: yup.string().email("Invalid email").required("Email is required"),
  //   contact: yup
  //     .string()
  //     .matches(phoneRegExp, "contact number is not valid")
  //     .required("Contact field is required"),
  //   company: yup.string().required("Company field is required"),
  //   message: yup.string().required("Message field is required"),
  // });
  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   reset,
  // } = useForm({
  //   resolver: yupResolver(validationSchema),
  // });
  // const onSubmit = (payload: any) => {};

  return (
    <section className="contact-us-section-wrapper" id="contact-us">
      <Container>
        <Heading
          className="contactUsHeading"
          heavy
          hasAnimation
          animation="fadeInUp"
          delay={1000}
        >
          <span>
            <p>Contact</p>
            <p>Us</p>
          </span>
        </Heading>
        <Text
          className="contactUsSlugan"
          bold
          hasAnimation={true}
          animation="fadeInUp"
          delay={1500}
        >
          Best way To Reach Out
        </Text>
        <div>
          <div className="info">
            <div className="address">
              <i>
                <FaLocationArrow />
              </i>
              <span>
                <h4>Location:</h4>
                <p>Office - C6, Gondal Plaza, Islamabad</p>
              </span>
            </div>

            <div className="email">
              <i>
                <FaEnvelope />
              </i>
              <span>
                <h4>Email:</h4>
                <p>info@wattonetech.com</p>
              </span>
            </div>

            <div className="phone">
              <i>
                <FaMobile />
              </i>
              <span>
                <h4>Call:</h4>
                <p>+923300249819</p>
              </span>
            </div>
          </div>
          {/* <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <label style={{ marginTop: "15px" }}>
                  Name <strong style={{ color: "#B71F18" }}>*</strong>
                </label>
                <input
                  {...register("name")}
                  name="name"
                  placeholder="Name"
                  className=""
                  required={true}
                  style={{
                    width: "100%",
                    background: "transparent",
                    border: "1px solid #12679B",
                    color: "#12679B",
                    padding: "5px",
                    // marginBottom: "15px",
                  }}
                />
                {errors.name && (
                  <p
                    style={{
                      color: "red",
                      margin: 0,
                      padding: 0,
                      fontSize: "10px",
                    }}
                  >
                    {errors.name.message}
                  </p>
                )}
              </Col>
              <Col md={6}>
                <label style={{ marginTop: "15px" }}>
                  Email <strong style={{ color: "#B71F18" }}>*</strong>
                </label>
                <input
                  {...register("email")}
                  name="email"
                  placeholder="Email"
                  className=""
                  required={true}
                  style={{
                    width: "100%",
                    background: "transparent",
                    border: "1px solid #12679B",
                    color: "#12679B",
                    padding: "5px",
                    // marginBottom: "15px",
                  }}
                />
                {errors.email && (
                  <p
                    style={{
                      color: "red",
                      margin: 0,
                      padding: 0,
                      fontSize: "10px",
                    }}
                  >
                    {errors.email.message}
                  </p>
                )}
              </Col>
              <Col md={6}>
                <label style={{ marginTop: "15px" }}>
                  Contact <strong style={{ color: "#B71F18" }}>*</strong>
                </label>
                <input
                  {...register("contact")}
                  name="contact"
                  placeholder="Contact"
                  className=""
                  required={true}
                  style={{
                    width: "100%",
                    background: "transparent",
                    border: "1px solid #12679B",
                    color: "#12679B",
                    padding: "5px",
                    // marginBottom: "15px",
                  }}
                />
                {errors.contact && (
                  <p
                    style={{
                      color: "red",
                      margin: 0,
                      padding: 0,
                      fontSize: "10px",
                    }}
                  >
                    {errors.contact.message}
                  </p>
                )}
              </Col>
              <Col md={6}>
                <label style={{ marginTop: "15px" }}>
                  Company <strong style={{ color: "#B71F18" }}>*</strong>
                </label>
                <input
                  {...register("company")}
                  name="company"
                  placeholder="Company"
                  className=""
                  required={true}
                  style={{
                    width: "100%",
                    background: "transparent",
                    border: "1px solid #12679B",
                    color: "#12679B",
                    padding: "5px",
                    // marginBottom: "15px",
                  }}
                />
                {errors.company && (
                  <p
                    style={{
                      color: "red",
                      margin: 0,
                      padding: 0,
                      fontSize: "10px",
                    }}
                  >
                    {errors.company.message}
                  </p>
                )}
              </Col>
              <Col md={12}>
                <label style={{ marginTop: "15px" }}>
                  Message <strong style={{ color: "#B71F18" }}>*</strong>
                </label>
                <textarea
                  {...register("message")}
                  name="message"
                  placeholder="Message"
                  className=""
                  required={true}
                  rows={6}
                  style={{
                    width: "100%",
                    background: "transparent",
                    border: "1px solid #12679B",
                    color: "#12679B",
                    padding: "5px",
                    // marginBottom: "15px",
                  }}
                />
                {errors.message && (
                  <p
                    style={{
                      color: "red",
                      margin: 0,
                      padding: 0,
                      fontSize: "10px",
                    }}
                  >
                    {errors.message.message}
                  </p>
                )}
              </Col>
              <Col md={12}>
                <div style={{ marginTop: "15px" }}>
                  <Button
                    loading={false}
                    variant="primary"
                    outline={false}
                    block={true}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </form> */}
        </div>
      </Container>
    </section>
  );
};

export default ContactUs;
