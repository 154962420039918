import { FC } from 'react';

interface ICommon {
  type?: 'image' | 'video';
  src: string;
}

const Asset: FC<ICommon> = ({ type, src }) => {
  if (type === 'image') {
    return (
      <div
        className='slide-asset'
        style={{ backgroundImage: `url(${src})` }}
      ></div>
    );
  }
  return (
    <div className='slide-asset'>
      <video src={src}></video>
    </div>
  );
};

interface ISlide extends ICommon {
  children?: React.ReactNode;
}

const Slide: FC<ISlide> = ({ type, src, children }) => {
  return (
    <div className={`slide`}>
      <Asset type={type} src={src} />
      {children}
    </div>
  );
};

Slide.defaultProps = {
  type: 'image',
};

export default Slide;
