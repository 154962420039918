import Heading from "@app/atoms/typography/heading";
import Text from "@app/atoms/typography/text";
import { FC, useRef, useState } from "react";
import { Container } from "react-bootstrap";
// import {
//   MdDesignServices,
//   MdOutlineDeveloperMode,
//   MdOutlineSupportAgent,
// } from "react-icons/md";

const OurServices: FC = () => {
  const [sliderPosition, setSliderPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const sliderRef = useRef(null);

  const handleMouseDown = (event: any) => {
    setIsDragging(true);
    setStartX(event.pageX - sliderPosition);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (event: any) => {
    if (!isDragging) return;
    event.preventDefault();
    const newPosition = event.pageX - startX;
    console.log(sliderRef.current);
    const sliderWidth = sliderRef.current.offsetWidth;
    const maxSliderPosition = sliderWidth - window.innerWidth;
    if (newPosition >= 0 && newPosition <= maxSliderPosition) {
      setSliderPosition(newPosition);
    }
  };
  const sliderStyle = {
    transform: `translateX(-${sliderPosition}px)`,
  };

  return (
    <section className="our-services-section-wrapper" id="services">
      <Container>
        <Heading
          className="ourServicesHeading"
          heavy
          hasAnimation
          animation="fadeInUp"
          delay={1000}
        >
          <span>
            <p>Our</p>
            <p>Services</p>
          </span>
        </Heading>
        <Text
          className="ourServicesSlugan"
          bold
          hasAnimation={true}
          animation="fadeInUp"
          delay={1500}
        >
          Some Collaboration Examples
        </Text>
        <div className="slider-outer container-fluid">
          <div
            className="slider"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseUp}
            ref={sliderRef}
          >
            <div className="slider__slide" style={sliderStyle}>
              <div className="service-card">
                <div className="icon-box-outer experience-design">
                  <div className="icon-box-inner">
                    <h4 className="title">
                      Experience
                      <br />
                      Design
                    </h4>
                    <ul className="description">
                      <li>User Interface Design</li>
                      <li>User Experience Design</li>
                      <li>Animation Design</li>
                      <li>Experience Strategy</li>
                      <li>Design Ops</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="service-card">
                <div className="icon-box-outer frontend-development">
                  <div className="icon-box-inner">
                    {/* <div className="icon">
                    <i className="bi bi-briefcase" style="color: #ff689b"></i>
                  </div>  */}
                    <h4 className="title">
                      Frontend
                      <br />
                      Development
                    </h4>
                    <ul className="description">
                      <li>Next</li>
                      <li>React</li>
                      <li>Angular</li>
                      <li>VUE</li>
                      <li>HTML, CSS, Bootstrap</li>
                      <li>Material UI</li>
                      <li>Chakra UI</li>
                      <li>Ant Design</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="service-card">
                <div className="icon-box-outer application-development">
                  <div className="icon-box-inner">
                    {/* <div className="icon">
                    <i className="bi bi-briefcase" style="color: #ff689b"></i>
                  </div>  */}
                    <h4 className="title">
                      Application
                      <br />
                      Development
                    </h4>
                    <ul className="description">
                      <li>.Net</li>
                      <li>Java</li>
                      <li>Native Applications</li>
                      <li>Hybrid Applications</li>
                      <li>E-Commerce</li>
                      <li>CMS, Dashboards</li>
                      <li>QA</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="service-card">
                <div className="icon-box-outer content-design">
                  <div className="icon-box-inner">
                    {/* <div className="icon">
                    <i className="bi bi-briefcase" style="color: #ff689b"></i>
                  </div>  */}
                    <h4 className="title">
                      Content
                      <br />
                      Design
                    </h4>
                    <ul className="description">
                      <li>Content Design</li>
                      <li>Content Strategy</li>
                      <li>Content Benchmarking</li>
                      <li>Copy Wrting</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="service-card">
                <div className="icon-box-outer digital-marketing">
                  <div className="icon-box-inner">
                    <h4 className="title">
                      Digital
                      <br />
                      Marketing
                    </h4>
                    <ul className="description">
                      <li>Social Media Marketing</li>
                      <li>Facebook Media Marketing</li>
                      <li>Email Marketing</li>
                      <li>Marketing Strategy</li>
                      <li>Marketing Compaign</li>
                      <li>Pay Per Click</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="service-card">
                <div className="icon-box-outer graphics-designing">
                  <div className="icon-box-inner">
                    {/* <div className="icon">
                    <i className="bi bi-briefcase" style="color: #ff689b"></i>
                  </div>  */}
                    <h4 className="title">
                      Graphics
                      <br />
                      Designing
                    </h4>
                    <ul className="description">
                      <li>Logo Designing</li>
                      <li>Banner Designing</li>
                      <li>Brandig</li>
                      <li>Product Designing</li>
                      <li>Animatation Design</li>
                      <li>Flyers Designing</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default OurServices;
