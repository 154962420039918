import { Children, FC } from 'react';
import Slider from './slider';

interface IHeroBanner {
  children: React.ReactNode;
  variant?: 'primary' | 'secondry';
}

const HeroBanner: FC<IHeroBanner> = ({ children, variant }) => {
  //counting children
  const count = Children.count(children);
  //If one slide
  if (count < 2) {
    return <div className={`hero-main-slider ${variant}`}>{children}</div>;
  }
  //Create Slider
  return (
    <div className={`hero-main-slider ${variant}`}>
      <Slider>{children}</Slider>
    </div>
  );
};

HeroBanner.defaultProps = {
  variant: 'secondry',
};

export default HeroBanner;
