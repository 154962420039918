import { FC } from "react";
import HomeHeroSlider from "@app/modules/home-hero-slider";
import AboutUs from "@app/modules/about-us";
import OurProcess from "@app/modules/our-process";
import ScreenTakeoverSlider from "@app/modules/screen-takeover-slider";
import SingleSlideBanner from "@app/modules/single-slide-banner";
import SingleTwoColSection from "@app/modules/single-two-col-sec";
import TemperatureSection from "@app/modules/temperature-section";
import TermsConditionSection from "@app/modules/terms-conditions-section";
import TextAndCards from "@app/modules/text-and-cards";
import TwoColumnSection from "@app/modules/two-column-section";
import RenderInnerContent from "@app/modules/ugc-modal";
import Faqs from "@app/modules/faqs";
import VideoWithContent from "@app/modules/video-with-content";
import CountryPromotionImg from "@public/images/video-box-img-1.jpg";
import OurServices from "@app/modules/our-services";
import OurValues from "../../modules/our-values";
import ContactUs from "@app/modules/contact-us";

const HomeScreen: FC = () => {
  return (
    <>
      <HomeHeroSlider />
      <AboutUs />
      <OurServices />
      <OurProcess />
      <OurValues />
      <ContactUs />
      {/* <Faqs/>
      <ScreenTakeoverSlider/>
      <SingleSlideBanner/>
      <SingleTwoColSection/>
      <TemperatureSection/>
      <TermsConditionSection/>
      <TextAndCards/>
      <TwoColumnSection/>
      <RenderInnerContent/>
      <section>
        <VideoWithContent
          fullWidth
          variant='teal'
          videoThumbnail={CountryPromotionImg}
          videoUrl='/video/placeholder-video.mp4'
          videoOrder='start'
          title='Visit Bahrain'
          subTitle='BAHRAIN STOPOVER'
          text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac ipsum sodales porttitor a in in quis iaculis.'
          actionButtons={[
            {
              link: '/',
              text: 'Keep Reading',
              variant: 'secondary',
            },
          ]}
        />
      </section> */}
    </>
  );
};

export default HomeScreen;
