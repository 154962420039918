import CustomHead from '@app/layouts/head';
import HomeScreen from '@app/screens/home';

const Home = () => {
  return (
    <>
      <CustomHead
        title='Wattonetech | Software Solutions | Digital Marketing & Content Writing - To Innovate Serve'
        description='Your trusted source in Software Solutions and Digital Marketing services and support.'
        keywords='react, node, nest, react development, frontend development, frontend, backend, backend development, web, website, web apps, software, software development, software solutions, website design and development, mobile, mobile application, mobile app develoment, mobile application development, software development, software house, software company, best company, software maintanance, custom softwares, marketing agency, marketing, best marketing, content, content writing, customer support'
      />
      <HomeScreen />
    </>
  );
};

export default Home;
