import Heading from '@app/atoms/typography/heading';
import Text from '@app/atoms/typography/text';
import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const AboutUs: FC = () => {

    return (
        <section className='about-us-section-wrapper' id='about-us'>
            <Container>
                <Row className='align-items-center align-items-xl-center'>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Heading className='aboutUsHeading' heavy hasAnimation animation='fadeInUp' delay={1000}>
                            <span><p>About</p><p>Wattone Tech</p></span>
                        </Heading>
                        <Text className='aboutUsSlugan' bold hasAnimation={true} animation='fadeInUp' delay={1500}>
                            A Brief Background Intoduction
                        </Text>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Text className='aboutUsHeadline' bold hasAnimation={true} animation='fadeInUp' delay={1500}>
                            Wattonetech is your trusted source in Software Solutions and Digital Marketing services and support.
                        </Text>
                        <br />
                        <Text className='aboutUsDescription' hasAnimation={true} animation='fadeInUp' delay={2000}>
                            Wattonetech's business operations have expanded globally. We’re proud to be the leading team for outsourcing professionals and providing customized end-to-end solutions for small, medium, and large enterprises. Our exceptionally tailored services for distinct niche markets can help organizations focus on core business activities that account for significant returns on investment.
                        </Text>
                        <br />
                        <Text className='aboutUsDescription' hasAnimation={true} animation='fadeInUp' delay={2000}>
                            We work with driven entrepreneurs by collaborating across many disciplines such as business strategy, business model development, technological verification/evaluation/analysis, technology planning/resourcing, technical development, and if required appropriate corporate structures (including assistance with required investor documentation and disclosure).
                        </Text>
                        <br />
                    </Col>
                </Row>
            </Container>
        </section >
    );
};

export default AboutUs;
